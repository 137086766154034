.settings-body {
    background-color: var(--primary-color);
    color: black;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
}

@media (min-width: 480px) {
    .settings-body {
        min-width: 670px;
    }
}

@media (max-width: 479px) {
    .settings-button-group {
        display: flex;
        justify-content: center;
    }

    .settings-body {
        margin-top: 69px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0px 0px 12px 12px;
    }
}

.settings-wrapper {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.settings-group {
    display: block;
    flex: 1;
}

.setting {
    padding-right: 0.5rem;
}

.settings-row {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
}