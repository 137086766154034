.account-body {
    background-color: var(--primary-color);
    color: black;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
}

@media (min-width: 480px) {
    .account-body {
        min-width: 670px;
    }
}

@media (max-width: 479px) {
    .account-body {
        margin-top: 69px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0px 0px 12px 12px;
    }
}
.test {
    left: auto;
    top: auto;
}

.account-wrapper {
    margin: 1rem;
}

.account-group {
    display: block;
    flex: 1;
    margin: 0.5rem;
}

.account-divider {
    margin-top: 0.5rem !important;
}

.account-text {
    padding-right: 0.5rem;
}

.account-row {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.account-message {
    display: flex;
    margin-left: 1.5rem;
    align-items: center;
}

.success-text {
    color: #055219;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.failure-text {
    color: #a8120a;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.cancel {
    background-color: #c73200;
}

.email-account-user {
    margin-right: 20px !important;
}

.MuiSelect-select {
    min-width: 45px !important;
}