.App {
  text-align: center;
}

.theme-light {
  --primary-mobile-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #2f343d;
  --input-color: #ffffff;
  --switch-color: #ffffff;
  --input-font: #000000;
  --font-color: #3e454f;
  --selected-radio-color: #3f51b5;
  --selected-border-color: #3f51b5;
  --radio-color: #ffffff;
  --radio-ring: #6e6e6e;
  --header-font-color: #ffffff;
  --panel-font-color: #000000;
  --light-button: #c7c7c7;
  --border-color: #bdbdbd;
}

.theme-dark {
  --primary-mobile-color: #2f343d;
  --primary-color: #2f343d;
  --secondary-color: #181616;
  --input-color: #6e6e6e;
  --switch-color: #aaaaaa;
  --input-font: #000000;
  --font-color: #aaaaaa;
  --selected-radio-color: #3f51b5;
  --selected-border-color: #aaaaaa;
  --radio-color: #aaaaaa;
  --radio-ring: #aaaaaa;
  --header-font-color: #ffffff;
  --panel-font-color: #ffffff;
  --light-button: #aaaaaa;
  --border-color: #4f4f4f;
}

.text{
  color: var(--font-color);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
}

a {
  color: var(--panel-font-color);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
}

.header-text {
  color: var(--header-font-color);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

.panel-header-text {
  color: var(--panel-font-color);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

input {
  background-color: var(--input-color);
}

input:focus {
  background-color: var(--input-color) !important;
}

.MuiFormLabel-root.Mui-error {
  color: #e60000 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e60000 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--selected-border-color) !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: var(--selected-radio-color) !important;
}

.MuiRadio-root {
  color: var(--radio-color) !important;
}

::placeholder {
  color: var(--input-font);
}

.MuiPaper-root {
  background-color: var(--primary-color) !important;
  color: var(--font-color) !important;
}

.MuiListItem-root, .MuiButton-text, .MuiSelect-icon, .MuiFormLabel-root, .MuiInputBase-input, .MuiExpansionPanelSummary-expandIcon, .MuiPickersClockNumber-clockNumber {
  color: var(--font-color) !important;
}

.MuiInput-underline:before {
  border-bottom-color: var(--font-color) !important;
}

.MuiRadio-colorPrimary {
  color: var(--radio-ring) !important;
}

.MuiPickersClockNumber-clockNumberSelected {
  color: var(--header-font-color) !important;
}

.MuiExpansionPanelDetails-root {
  padding: 0 !important;
}

.App-header {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}


@media (min-width: 480px) {
  .App-header {
    display: flex;
    flex-direction: column;
  }
  body {
    background-color: var(--secondary-color);
  }
}

@media (max-width: 479px) {
  body {
    background-color: var(--primary-mobile-color);
  }

  .body {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
  }
}


@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
   } 
} 

.expansion-enter, .expansion-appear {
  opacity: 0;
  max-height: 0px;
}

.expansion-enter-active, .expansion-appear-active {
  opacity: 1;
  max-height: 500px;
  transition: all 500ms;
}

.expansion-exit {
  opacity: 1;
  max-height: 500px;
}

.expansion-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: all 500ms;
}