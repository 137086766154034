.weekday-picker {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    justify-content: center;
}

@media (max-width: 479px) {
    .weekday-picker {
        margin: 1rem 0 1rem 0;
    }
}