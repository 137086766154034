.account-menu {
    margin: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 10px;
    width: 150px;
    height: 135x;
    position: absolute;
    z-index: 10;
    top: 3.5rem;
    right: 0.3rem;
    box-shadow: 0px 10px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.account-button {
    cursor: pointer;
}

ul {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    list-style-type: none;
}

li {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
}