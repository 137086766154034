.activities-body {
    background-color: var(--primary-color);
    color: black;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
}

.activity-detail-panel {
    padding: 8px;
}

.activity-subtext {
    font-size: 14px;
    margin: 0 !important;
    align-self: center;
}

@media (min-width: 480px) {
    .activities-body {
        min-width: 670px;
    }

    .add-task-container {
        display: flex;
        justify-content: flex-end;
        padding: 0 1rem 1rem 0;
    }
}

@media (max-width: 479px) {
    .settings-button-group {
        display: flex;
        justify-content: center;
    }

    .activities-body {
        margin-top: 69px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0px 0px 12px 12px;
    }

    .add-task-container {
        right: 43%;
        bottom: 2%;
        position: fixed;
    }
}

.tasks-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px;
}

.task-button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.activites-wrapper {
    margin: 1rem;
}

.edited {
    color: cornflowerblue;
}

.activity-group-name {
    font-weight: bold;
    margin: 0 !important;
}

.alarm-time-divider {
    width: 85%;
    align-self: center;
}

.alarm-setting-group {
    display: flex;
    flex-direction: row;
}

.alarm-time {
    margin: 0;
    font-size: 2rem;
    align-self: center;
}

.alarm-column-one {
    flex: 4;
}

.alarm-column-two {
    flex: 1;
}

.add-task-button-border {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0px 10px 15px -1px rgba(0,0,0,0.65), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.task-delete {
    color: #ff6666 !important;
}

.add-task-button-plus {
    height: 3rem !important;
    width: 3rem !important;
    color: white;
}

.add-task-button {
    display: flex;
    justify-content: center;
    border-radius: 2rem;
    height: 2.9rem;
    width: 2.9rem;
    background-color: #00c774;
    transition: transform 250ms;
}

.add-task-button:active {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
 }

 .alarm-summary-panel {
     display: flex;
     flex-direction: column;
     flex: 100%;
     margin: 0.3rem 0 0.3rem 0;
 }

 .task-room-picker-row {
     margin: 0 0 1rem 1rem !important;
 }
 
