.basement-panel {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 2px rgba(0,0,0,0.12) !important;
}

.sump-group {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 8px 15px 8px 15px
}

.sump-icon {
    /* height: 75px; */
    width: 175px;
}

.sump-text-group {
    display: flex;
    justify-content: center;
}

.sump-measure-group {
    align-self: center;
    display: flex;
    flex-direction: column;
}

.sump-text {
    margin: 0.75rem 0rem 0rem 0.5rem;
    align-items: center;
}

.alert {
    color: red;
    font-weight: bolder;
}

.healthy {
    color: green;
    font-weight: bolder;
}