.day-picker {
    background-color: #ededed;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00c774;
    font-size: 1.5rem;
    margin: 0.5rem;
    outline: none;
    border: none;
}

.selected {
    background-color: #00c774;
    color: white;
}

@media (max-width: 479px) {
    .day-picker {
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin: 0.25rem;
        font-size: 20px;
        display: block;
        border: none;
    }
}