.light-group {
    display: flex;
    align-items: center;
}

.light-chevron {
    padding: 8px;
    border-radius: 40px;
    color: var(--font-color) !important;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expanded {
    transform: rotate(90deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.light-group-expansion {
    border-color: darkgray;
    border-radius: 1px;
}

.light-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70px;
    font-size: 0.9rem;
    margin: 0.5rem 0.2rem 0.5rem 0.2rem;
}

.light-spacer-one {
    padding: 20px 24px 20px 24px;
}

.light-spacer-two {
    padding: 16px;
}

.light-text-small {
    font-size: 0.7rem;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0.5rem 0.2rem 0.5rem 0.2rem;
}

.brightness-icon {
    height: 16px !important;
    width: 16px !important;
    padding: 8px;
}

.light-button {
    border: 1px solid var(--border-color) !important;
    border-radius: 10px !important;
}
