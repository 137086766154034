
.small-text {
    margin: 0 0 0 0.25rem;
    font-size: 0.75rem;
    align-items: center;
}

.small-text-group {
    margin-left: 2rem;
    display: flex;
    justify-content: center;
}

.small-text-container {
    display: flex;
}

.panel-text {
    margin-left: 1rem !important;
}