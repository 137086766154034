.auto-open-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    width: 275px;
    height: 140px;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 30%;
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.7);
}

@media(max-width: 479px) {
    .auto-open-menu {
        top: 350%;
        left: 18%;
    }
}

.auto-open-text {
    align-self: center;
}

.auto-open-button {
    width: 100%;
}

.location-menu-group {
    display: flex;
    flex-direction: column;
}

.location-close-icon {
    cursor: pointer;
    align-self: flex-end !important;
    color: grey;
    align-self: center;
    margin: 1rem 1rem 0 0;
}

.reduce-margin {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}