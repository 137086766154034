input {
    border-radius: 12px;
    border-color: lightgray;
    width: 80%;
    padding: 8px;
    margin: 0;
}

.error-text {
    margin: 2px;
    color: #e60000;
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.spacer {
    margin: 2px;
    height: 16px;
}

.error-input {
    border-color: #e60000;
}

.user-pass-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-pass-input {
  width: 270px;
}

@media (max-width: 479px) {
  .user-pass-input {
    width: 300px;
  }
}