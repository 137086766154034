.weather-icon {
    height: 200px;
}

.home-icon {
    height: 100px;
    width: 200px;
}

.temp-container {
    display: flex;
}

.temp-external-container {
    display: flex;
    flex-direction: row;
}

.temp-home-container {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
}

.internal-temp {
    margin: 0 4rem 0 -7.7rem;
    font-size: 40px;
    font-weight: bolder;
}

.external-temp {
    margin-left: -1rem;
    padding-top: 1.9rem;
    font-size: 35px;
    font-weight: bolder;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.min-max {
    font-size: 20px;
    margin: 0;
}

.top-fade {
    background: -webkit-linear-gradient(#27aedb, var(--primary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.external {
    margin: 0;
}

.bottom-fade {
    background: -webkit-linear-gradient(var(--primary-color), #db5127);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 479px) {
    .weather-icon {
        height: 150px;
        object-fit: contain;
    }

    .min-max {
        font-size: 17px;
        margin: 0;
    }

    .home-icon {
        padding-top: 5.25rem;
        height: 75px;
        object-fit: contain;
    }

    .external-temp {
        margin-left: -0.5rem;
        padding-top: 1.1rem;
        font-size: 30px;
    }

    .internal-temp {
        font-size: 35px;
    }
}