.home-body {
    background-color: var(--primary-color);
    color: black;
    border-radius: 0 0 10px 10px;

}

@media (min-width: 480px) {
    .home-body {
        display: flex;
        min-width: 670px;
    }    
}

@media (max-width: 479px) {
    .home-body {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 90px;
    }

    .center {
        height: 100%;
    }
}