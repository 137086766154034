.button-container {
    margin: 1rem !important;
    border-radius: 12px !important;
}

.green {
    background-color: #00c774 !important;

}

.blue {
    background-color: #1a75ff !important;
}

.red {
    background-color: #ff3333 !important;
}

.my-button {
    color: var(--header-font-color) !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;  
    border-radius: 12px !important;
    font-size: 16px !important;
    cursor: pointer !important;
    padding: 10px 20px !important;
    border-color: #5c5c5c !important;
    border-width: 2px !important;
    border-style: outset !important;
}

.my-button:active {
    border-style: inset !important;
}


.button-border {
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 2rem !important;
    background-color: white !important;
    box-shadow: 0px 10px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.add-item {
    background-color: #00c774;
    display: flex;
    justify-content: center;
}

.remove-item {
    background-color: lightgray;
}

.button-item {
    cursor: pointer;
    border-radius: 2rem;
    width: 2rem !important;
    height: 2rem !important; 
}

.button-icon {
    height: 2rem !important;
    width: 2rem !important;
    color: white;
}

.remove-item:hover {
    background-color: red;
    cursor: pointer;
}

.button-item {
    display: block;
    margin: 0 auto;
  }

.button-item[rotate='1'] {
    animation: rotate 500ms 1;
}
  
@keyframes rotate {
100% {
    transform: rotate(180deg);
}
}