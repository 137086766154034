.table-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 1rem;
}

.table-end-item {
    flex-direction: row-reverse;
}

table {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 479px) {
    td, tr {
        flex: 0;
    }

    td {
        max-width: 160px;
    }
}

td {
    max-width: 250px;
}

tr {
    margin-top: 0.5rem;
    align-items: center;
}

.table-header {
    margin-top: 0.5rem;
}

input {
    border-color: lightgray;
}

input:focus {
    background-color: lightgray;
  }

.account-roles {
    justify-content: space-between;
}

.input-error {
    border-color: #e60000;
}