.garage-success-text {
    color: #055219;
    align-self: center;
}

.border-success-icon {
    border-radius: 40px;
    background-color: white;
    height: 1.5rem;
    width: 1.5rem;
    align-self: center;
}

.device-text {
    margin-left: 1rem;
}

.device-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 16px;
}

.device-row {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}