.account-container {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.account-border {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    background-color: white;
}

.account-center {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-color: #6495ed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.ripple {
    background-position: center;
    transition: background 0.8s;
  }

  .ripple:hover {
    background: #47a7f5 radial-gradient(circle, transparent 1%, #99bbf7 1%) center/15000%;
  }
  
  .ripple:active {
    background-color: #99bbf7;
    background-size: 100%;
    transition: background 0s;
  }
  