.MuiPickersToolbar-toolbar, .MuiPickersClockNumber-clockNumberSelected, .MuiPickersClockPointer-pointer, .MuiPickersClock-pin {
    background-color: #00c774 !important;
}

.MuiPickersClockPointer-thumb {
    border: 14px solid #00c774 !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
}