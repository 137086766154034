.garage-panel {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 2px rgba(0,0,0,0.12) !important;
}

.status-text-bold {
    font-weight: bold;
    margin: 0 0.5rem 0.5rem 0;
}

.status-text {
    font-size: 0.8rem;
    margin: 0rem 0.5rem 0 0;
}

.door-groups {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px 15px 8px 15px;
}

@media (max-width: 479px) {
    .MuiExpansionPanelActions-root {
        justify-content: center !important;
    }
}